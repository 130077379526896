import colors from './colors'
import fonts from './fonts'
import textStyles from './textStyles'
import sizes from './sizes'
import zIndexes from './zindexes'

export type TColors = typeof colors
export type TFonts = typeof fonts
export type TTextStyles = typeof textStyles
export type TSizes = typeof sizes
export type TZIndexes = typeof zIndexes

export type Theme = {
  colors: TColors
  fonts: TFonts
  textStyles: TTextStyles
  sizes: TSizes
  zIndexes: TZIndexes
}

const theme: Theme = {
  colors: {
    ...colors,
  },
  fonts: {
    ...fonts,
  },
  textStyles: {
    ...textStyles,
  },
  sizes: {
    ...sizes,
  },
  zIndexes: {
    ...zIndexes,
  },
}

export enum themesKey {
  theme = 'theme',
}

export const themes: { [key in themesKey]: Theme } = {
  [themesKey.theme]: theme,
}
