const colors = {
  primary: {
    pureWhite: '#FFF',
    pureBlack: '#000',
    red: '#FF5555',
    green: '#00FF00',
    grey: '#808080',
    grey1: '#727272',
    grey2: '#5E6366',
    lightGrey: '#F5F5F5',
    darkGrey: '#1e2022',
  },
  secondary: {},
}

export default colors
