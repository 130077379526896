import {
  declareFont,
  defineFont,
  fontStyles,
  fontWeights,
  Font,
} from '../utils/FontUtils'

enum FontKeys {
  PoppinsLight = 'PoppinsLight',
  PoppinsRegular = 'PoppinsRegular',
  PoppinsMedium = 'PoppinsMedium',
  PoppinsBold = 'PoppinsBold',
}

export const declarations: { [key in FontKeys]: Font } = {
  PoppinsLight: {
    basename: 'PoppinsLight',
    fontFamily: 'Poppins',
    fontWeight: fontWeights.light,
    fontStyle: fontStyles.normal,
    fallback: 'sans-serif',
  },
  PoppinsRegular: {
    basename: 'PoppinsRegular',
    fontFamily: 'Poppins',
    fontWeight: fontWeights.regular,
    fontStyle: fontStyles.normal,
    fallback: 'sans-serif',
  },
  PoppinsMedium: {
    basename: 'PoppinsMedium',
    fontFamily: 'Poppins',
    fontWeight: fontWeights.medium,
    fontStyle: fontStyles.normal,
    fallback: 'sans-serif',
  },
  PoppinsBold: {
    basename: 'PoppinsBold',
    fontFamily: 'Poppins',
    fontWeight: fontWeights.bold,
    fontStyle: fontStyles.normal,
    fallback: 'sans-serif',
  },
}

export const fontsFaces = (): string =>
  Object.values(declarations)
    .filter((font) => !font.isAlreadyDeclared && font)
    .map(declareFont)
    .join(' ')

const fonts: { [key in FontKeys]?: string } = {
  PoppinsLight: defineFont(declarations.PoppinsLight),
  PoppinsRegular: defineFont(declarations.PoppinsRegular),
  PoppinsMedium: defineFont(declarations.PoppinsMedium),
  PoppinsBold: defineFont(declarations.PoppinsBold),
}

export default fonts
