import { createGlobalStyle } from 'styled-components'

import { fontsFaces } from './fonts'

export const GlobalStyles = createGlobalStyle`
  ${fontsFaces()}
  :root {
    --radius-blocks--mobile: 2rem;
    --radius-blocks--desktop: 4rem;
  }
  * {
    box-sizing: border-box;
    &:before, &:after {
      box-sizing: border-box;
    }
  }
  html {
    font-size: 62.5%;
  }
  body {
    padding: 0;
    margin: 0;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }  
  p {
    margin: 0;
    padding: 0;
  }
  a {
    text-decoration: none;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
  img {
    max-width: 100%;
    height: auto;
    vertical-align: middle
  }
  picture img {
    display: block;
  }
  button {
    padding: 0;
    border: 0;
    &:not(:disabled) {
      cursor: pointer;
    }
  }  
  :focus {
    outline: 0.1rem dotted #000;
  }
  :focus:not(:focus-visible) {
    outline: none;
  }
  :focus-visible {
    outline: 0.1rem dotted #000;
  }
  input::-ms-clear {
    display: none;
  }
`
