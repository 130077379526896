import { FC, useState } from 'react'

import { Icons } from '../../Icon'
import { Link } from '../types'

import * as SC from './styled'

export const NavItem: FC<Link> = ({
  link,
  label,
  active,
  hasSubNav,
  subNav,
  isNotAvailable,
}) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)
  return (
    <SC.NavItem>
      <SC.NavItemLink
        href={hasSubNav ? '#' : link.href}
        as={isNotAvailable ? 'span' : undefined}
        $isActive={active}
        onClick={() => setIsSubMenuOpen(!isSubMenuOpen)}
        $isNotAvailable={isNotAvailable}
      >
        {label}
        {hasSubNav && <SC.SubNavIcon icon={Icons.chevronDown} />}
      </SC.NavItemLink>
      {hasSubNav && subNav && (
        <SC.Menu $isOpen={isSubMenuOpen}>
          <SC.SubMenuHeader>
            <SC.BackIcon
              icon={Icons.arrowLeft}
              onClick={() => setIsSubMenuOpen(false)}
            />
          </SC.SubMenuHeader>
          <SC.MainNav>
            <SC.NavList>
              {subNav.sections.map((item, index) => (
                <SC.SubNavSection key={index}>
                  <SC.SubNavSectionTitle>
                    {item.title.label}
                  </SC.SubNavSectionTitle>
                  <SC.SubNavSectionLinks>
                    {item.links.map((link, index) => (
                      <SC.SubNavSectionLink
                        key={index}
                        {...link.link}
                        as={link.isNotAvailable ? 'span' : undefined}
                        $isNotAvailable={link.isNotAvailable}
                      >
                        {link.label}
                      </SC.SubNavSectionLink>
                    ))}
                  </SC.SubNavSectionLinks>
                </SC.SubNavSection>
              ))}
            </SC.NavList>
          </SC.MainNav>
        </SC.Menu>
      )}
    </SC.NavItem>
  )
}
