import styled from 'styled-components'

import Header from '../../components/Header'
import { mq, sizes } from '../../theme'

export const Layout = styled.div`
  background-color: ${({ theme }) => theme.colors.primary.pureWhite};
  max-width: ${({ theme }) => theme.sizes.scopingLarge / 10}rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  @supports (min-height: 100svh) {
    min-height: 100svh;
  }
  @media ${mq(sizes.tablet)} {
    overflow: visible;
    padding: 0 2rem;
  }
  @media ${mq(sizes.scopingLarge)} {
    padding: 0;
  }
`

export const StyledHeader = styled(Header)``

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: ${({ theme }) => theme.zIndexes.default};
  width: 100%;
  flex: 1;
  margin-top: 2rem;
  padding: 0 1rem;
  gap: 4rem;
  @media ${mq(sizes.tablet)} {
    padding: 0 2rem;
    margin-top: 3rem;
    z-index: ${({ theme }) => theme.zIndexes.default};
  }
`
