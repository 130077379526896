import styled, { css } from 'styled-components'
import Link from 'next/link'

import Icon from '../../Icon'

export const Header = styled.header`
  z-index: ${({ theme }) => theme.zIndexes.navigation};
  position: relative;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 2.4rem;
`

export const BurgerIcon = styled(Icon)`
  width: 2.4rem;
  height: 2.4rem;
`

export const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
`

export const Logo = styled(Icon)`
  width: 5.5rem;
  height: 5rem;
`

export const LogoText = styled.span`
  ${({ theme }) => theme.textStyles.link}
  color: ${({ theme }) => theme.colors.primary.red};
  margin-top: 0.2rem;
`

export const Menu = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  top: 0;
  left: ${({ $isOpen }) => ($isOpen ? '0' : '-100%')};
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.primary.pureWhite};
  transition: left 0.3s ease-in-out;
  z-index: ${({ theme }) => theme.zIndexes.navigation};
  padding: 2rem 2.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const MenuHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const CloseIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;
  color: ${({ theme }) => theme.colors.primary.pureBlack};
`

export const MainNav = styled.nav`
  width: 100%;
`

export const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 1rem;
`

export const NavItem = styled.li`
  list-style: none;
`

export const NavItemLink = styled(Link)<{
  $isActive?: boolean
  $isNotAvailable?: boolean
}>`
  ${({ theme }) => theme.textStyles.link}
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.primary.red : theme.colors.primary.pureBlack};

  & svg {
    color: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.primary.red : theme.colors.primary.pureBlack};
  }

  ${({ $isNotAvailable }) =>
    $isNotAvailable &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    `}
`

export const SubNavIcon = styled(Icon)`
  width: 1.6rem;
  height: 1.6rem;
  margin-left: 0.5rem;
  transform: rotate(-90deg);
`

export const Divider = styled.hr`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.primary.pureBlack};
  opacity: 0.1;
  margin: 1rem 0;
`

//

export const SubMenuHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2rem;
`

export const BackIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;
  color: ${({ theme }) => theme.colors.primary.pureBlack};
`

export const SubNavSection = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`

export const SubNavSectionTitle = styled.span`
  ${({ theme }) => theme.textStyles.titleH4}
  color: ${({ theme }) => theme.colors.primary.pureBlack};
  margin: 0 0 1rem 0;
`

export const SubNavSectionLinks = styled.ul`
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`

export const SubNavSectionLink = styled(Link)<{ $isNotAvailable?: boolean }>`
  ${({ theme }) => theme.textStyles.text}
  color: ${({ theme }) => theme.colors.primary.pureBlack};

  ${({ $isNotAvailable }) =>
    $isNotAvailable &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    `}
`
