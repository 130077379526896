const zIndexes = {
  sub: -1,
  background: 1,
  default: 10,
  forefront: 50,
  navigation: 150,
  footer: 100,
  modal: 200,
}

export default zIndexes
