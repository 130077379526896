const PORT = String(process.env.PORT) !== '80' && String(process.env.PORT)

const APP = {
  APP_ENV: String(process.env.APP_ENV) || 'production',
  APP_VERSION: String(process.env.APP_VERSION) || '',
  PORT,
  APP_URL: String(process.env.NEXT_PUBLIC_APP_URL),
  NO_INDEX: String(process.env.NO_INDEX) === 'true' || false,
}

export default APP
