import { useTranslation } from 'react-i18next'
import { NextPage } from 'next'
import { useRouter } from 'next/router'
import styled from 'styled-components'

import { initWithLangAndLabels } from '../i18n'
import { PageProps } from '../relay/common/PageProps'
import Html from '../components/Html'
import { REVALIDATE_1_WEEK } from '../utils/Cache'
import { MainLayout, MainLayoutProps } from '../layouts'
import { getHeaderProps } from '../relay/common/Header'
import { getFooterProps } from '../relay/common/Footer'
import Button from '../components/Button'

export type NotFoundPageProps = PageProps & MainLayoutProps

const Title = styled.h1`
  ${({ theme }) => theme.textStyles.titleH2};
  color: ${({ theme }) => theme.colors.primary.pureBlack};
`

const NotFoundPage: NextPage<NotFoundPageProps> = ({
  headerProps,
  footerProps,
}) => {
  const { t } = useTranslation()
  const router = useRouter()

  const goToHome = () => {
    router.push('/')
  }

  return (
    <MainLayout headerProps={headerProps} footerProps={footerProps}>
      <Title>{t('404_title')}</Title>
      <Html>{t('404_text')}</Html>
      <Button onClick={goToHome}>{t('404_button')}</Button>
    </MainLayout>
  )
}

export async function getStaticProps(): Promise<{
  props: NotFoundPageProps
  revalidate: number
}> {
  const i18n = initWithLangAndLabels('fr', {})

  return {
    props: {
      i18n,
      headerProps: getHeaderProps('/'),
      footerProps: getFooterProps(),
    },
    revalidate: REVALIDATE_1_WEEK,
  }
}

export default NotFoundPage
