import { FC } from 'react'
import cx from 'classnames'

import { HeaderProps } from '../types'

import { NavItem } from './components'
import * as SC from './styled'

export const DesktopNav: FC<HeaderProps> = ({
  className,
  mainNav,
  logo,
  rightNav,
}) => {
  return (
    <SC.Header className={cx('Header', className)}>
      <SC.MainNav>
        <SC.NavList>
          {mainNav.map((item, index) => (
            <NavItem key={index} {...item} />
          ))}
        </SC.NavList>
      </SC.MainNav>
      <SC.LogoContainer href="/" aria-label="Home" prefetch={false}>
        <SC.Logo {...logo.icon} />
      </SC.LogoContainer>
      <SC.RightNav>
        <SC.NavList>
          {rightNav.map((item, index) => (
            <NavItem key={index} {...item} />
          ))}
        </SC.NavList>
      </SC.RightNav>
    </SC.Header>
  )
}
