import type { AppProps } from 'next/app'
import { ThemeProvider } from 'styled-components'
import { FC } from 'react'

import { GlobalStyles, themes } from '../theme'
import I18nProvider from '../i18n/Provider'
import Metas from '../components/Metas'
import { PageProps } from '../relay/common/PageProps'

import NotFoundPage from './404'
import MaintenancePage from './_maintenance'

const Application: FC<AppProps<PageProps>> = ({ Component, ...rest }) => {
  const JSCComponent = Component as FC<PageProps>
  const pageProps: PageProps = rest?.pageProps

  return (
    <>
      <GlobalStyles />
      <I18nProvider store={pageProps?.i18n}>
        <ThemeProvider theme={themes['theme']}>
          <Metas {...pageProps?.metas} />
          {pageProps?.maintenance?.enabled ? (
            <MaintenancePage {...pageProps} />
          ) : (rest as any)?.error ? (
            <NotFoundPage {...pageProps} />
          ) : (
            <JSCComponent {...pageProps} />
          )}
        </ThemeProvider>
      </I18nProvider>
    </>
  )
}

export default Application
