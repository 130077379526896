const API_URL = process.env.NEXT_PUBLIC_API_URL + '/api'
const API_MEDIA_URL = process.env.NEXT_PUBLIC_API_URL
const API_CONTACT_URL = process.env.NEXT_PUBLIC_API_CONTACT_URL

export default {
  DEBUG: false,
  API_URL,
  API_MEDIA_URL,
  API_CONTACT_URL,
}
