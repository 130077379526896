import React from 'react'
import Head from 'next/head'
import { useTheme } from 'styled-components'

import { app } from '../../configuration'
import APP from '../../configuration/app'

export type MetasProps = {
  applicationName?: string
  title?: string
  description?: string
  image?: string
  twitter_card?: string
  url?: string
  type?: string
  twitter_account?: string
  locale?: string
  robots?: string
  noindex?: boolean
}

const Metas: React.FC<MetasProps> = ({
  applicationName,
  title,
  description,
  type = 'website',
  image,
  twitter_card,
  url,
  twitter_account,
  robots,
  locale,
  noindex,
}) => {
  const theme = useTheme()

  return (
    <Head>
      <meta charSet="utf-8" />
      {title && <title>{title}</title>}
      {robots && <meta property="robots" content={robots} />}
      {url && <link rel="canonical" href={url} />}
      {noindex || app.NO_INDEX ? (
        <meta name="robots" content="noindex, nofollow" />
      ) : (
        <>
          <meta name="robots" content="index, follow" />
          <meta
            name="googlebot"
            content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          />
        </>
      )}
      <meta name="screen-orientation" content="portrait" />
      <meta
        content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=5"
        name="viewport"
      />

      {title && <meta property="og:title" content={title} />}
      {description && <meta name="description" content={description} />}
      {description && <meta property="og:description" content={description} />}
      {type && <meta property="og:type" content={type} />}
      {url && <meta property="og:url" content={url} />}
      <meta property="og:image" content={APP.APP_URL + '/images/logo.svg'} />
      {locale && <meta property="og:locale" content={locale} />}
      <meta
        name="keywords"
        content="site web, digital, web, mobile, développement mobile, développement web, performance, technologies web, technologies, applications mobiles, UX/UI, solutions personnalisées, agence France, projets digitaux, expertise, IT"
      />
      <meta name="author" content="Pioss" />

      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {twitter_card && <meta name="twitter:card" content={twitter_card} />}
      {image && <meta name="twitter:image" content={image} />}
      {twitter_account && (
        <>
          <meta property="twitter:site" content={twitter_account} />
          <meta name="twitter:creator" content={twitter_account} />
        </>
      )}

      <link rel="shortcut icon" href="/favicon.ico" />
      <link rel="icon" href="/favicon.ico" />
      <link rel="icon" type="image/vnd.microsoft.icon" href="/favicon.ico" />
      <link rel="icon" type="image/x-icon" href="/favicon.ico" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/manifest.json" />

      <meta name="theme-color" content={theme.colors.primary.pureBlack} />
      <meta name="mobile-web-app-capable" content="yes" />

      <meta name="apple-mobile-web-app-title" content={applicationName} />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />

      <meta name="application-name" content={applicationName} />
      <meta name="msapplication-tooltip" content={applicationName} />
      <meta name="msapplication-starturl" content="/" />

      <meta name="msapplication-tap-highlight" content="no" />

      <meta name="full-screen" content="yes" />
      <meta name="browsermode" content="application" />

      <meta name="nightmode" content="enable" />

      <meta name="layoutmode" content="fitscreen" />

      <meta name="imagemode" content="force" />
    </Head>
  )
}

export default Metas
