import fonts from './fonts'

const textStyles = {
  titleH1: `
    ${fonts.PoppinsBold}
    font-size: 6.4rem;
    line-height: 1.2;
  `,
  titleH2: `
    ${fonts.PoppinsBold}
    font-size: 4.8rem;
    line-height: 1.2;
  `,
  titleH3: `
    ${fonts.PoppinsMedium}
    font-size: 2.8rem;
    line-height: 1.2;
  `,
  titleH4: `
    ${fonts.PoppinsBold}
    font-size: 1.8rem;
    line-height: 1.2;
  `,
  titleH5: `
    ${fonts.PoppinsBold}
    font-size: 1.6rem;
    line-height: 1.2;
  `,
  link: `
    ${fonts.PoppinsMedium}
    font-size: 2rem;
    line-height: 1.5;
  `,
  text: `
    ${fonts.PoppinsRegular}
    font-size: 1.8rem;
    line-height: 1.5;
  `,
  smallText: `
    ${fonts.PoppinsRegular}
    font-size: 1.6rem;
    line-height: 1.5;
  `,
}

export default textStyles
