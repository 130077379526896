import { FC, useRef, useState } from 'react'

import { Icons } from '../../Icon'
import { Link } from '../types'

import * as SC from './styled'

const CLOSE_TIMEOUT = 200

export const NavItem: FC<Link> = ({
  link,
  label,
  active,
  hasSubNav,
  isNotAvailable,
  subNav,
}) => {
  const [isSubNavOpen, setIsSubNavOpen] = useState(false)
  const [isSubSubNavOpen, setIsSubSubNavOpen] = useState(false)
  const subNavTimerRef = useRef<number>()
  const subSubNavTimerRef = useRef<number>()

  const handleMouseEnter = () => {
    clearTimeout(subNavTimerRef.current)
    setIsSubNavOpen(true)
  }

  const handleMouseLeave = () => {
    subNavTimerRef.current = window.setTimeout(() => {
      setIsSubNavOpen(false)
    }, CLOSE_TIMEOUT)
  }

  const handleSubNavMouseEnter = () => {
    clearTimeout(subNavTimerRef.current)
    setIsSubNavOpen(true)
  }

  const handleSubNavMouseLeave = () => {
    subNavTimerRef.current = window.setTimeout(() => {
      setIsSubNavOpen(false)
    }, CLOSE_TIMEOUT)
  }

  const handleSubSubNavMouseEnter = () => {
    clearTimeout(subSubNavTimerRef.current)
    setIsSubSubNavOpen(true)
  }

  const handleSubSubNavMouseLeave = () => {
    subSubNavTimerRef.current = window.setTimeout(() => {
      setIsSubSubNavOpen(false)
    }, CLOSE_TIMEOUT)
  }

  return (
    <SC.NavItem onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {hasSubNav ? (
        <SC.NavItemEl $isActive={active}>
          {label}
          {hasSubNav && (
            <SC.SubNavIcon icon={Icons.chevronDown} $open={isSubNavOpen} />
          )}
        </SC.NavItemEl>
      ) : (
        <SC.NavItemLink
          {...link}
          $isActive={active}
          as={isNotAvailable ? 'span' : undefined}
          prefetch={false}
        >
          {label}
          {isSubNavOpen && isNotAvailable && (
            <SC.NotAvailableContainer
              onMouseEnter={handleSubNavMouseEnter}
              onMouseLeave={handleSubNavMouseLeave}
            >
              Bientôt disponible
            </SC.NotAvailableContainer>
          )}
        </SC.NavItemLink>
      )}

      {isSubNavOpen && hasSubNav && subNav && (
        <SC.SubNavContainer
          onMouseEnter={handleSubNavMouseEnter}
          onMouseLeave={handleSubNavMouseLeave}
        >
          <SC.SubNavSections>
            {subNav.sections.map((section, index) => (
              <SC.SubNavSection key={index}>
                <SC.SubNavSectionTitle {...section.title.link}>
                  {section.title.label}
                </SC.SubNavSectionTitle>
                <SC.SubNavSectionLinks>
                  {section.links.map((link, index) => (
                    <SC.SubNavSectionLink
                      key={index}
                      {...link.link}
                      as={link.isNotAvailable ? 'span' : undefined}
                      prefetch={false}
                      onMouseEnter={
                        link.isNotAvailable
                          ? handleSubSubNavMouseEnter
                          : undefined
                      }
                      onMouseLeave={
                        link.isNotAvailable
                          ? handleSubSubNavMouseLeave
                          : undefined
                      }
                    >
                      {link.label}
                      {isSubSubNavOpen && link.isNotAvailable && (
                        <SC.NotAvailableContainer
                          onMouseEnter={handleSubSubNavMouseEnter}
                          onMouseLeave={handleSubSubNavMouseLeave}
                        >
                          Bientôt disponible
                        </SC.NotAvailableContainer>
                      )}
                    </SC.SubNavSectionLink>
                  ))}
                </SC.SubNavSectionLinks>
              </SC.SubNavSection>
            ))}
          </SC.SubNavSections>
        </SC.SubNavContainer>
      )}
    </SC.NavItem>
  )
}
