export { default as add } from './add.svg'
export { default as arrowLeft } from './arrowLeft.svg'
export { default as arrowRight } from './arrowRight.svg'
export { default as burger } from './burger.svg'
export { default as check } from './check.svg'
export { default as chevronDown } from './chevronDown.svg'
export { default as close } from './close.svg'
export { default as cube } from './cube.svg'
export { default as cube2 } from './cube2.svg'
export { default as euro } from './euro.svg'
export { default as eye } from './eye.svg'
export { default as files } from './files.svg'
export { default as github } from './github.svg'
export { default as house } from './house.svg'
export { default as linkedin } from './linkedin.svg'
export { default as littleStar } from './littleStar.svg'
export { default as loading } from './loading.svg'
export { default as logo } from './logo.svg'
export { default as mail } from './mail.svg'
export { default as phone } from './phone.svg'
export { default as pin } from './pin.svg'
export { default as plane } from './plane.svg'
export { default as search } from './search.svg'
export { default as starWhite } from './star-white.svg'
export { default as star } from './star.svg'
export { default as upload } from './upload.svg'
export { default as warning } from './warning.svg'
