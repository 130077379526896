import styled from 'styled-components'

export const Html = styled.div`
  ${({ theme }) => theme.textStyles.text}
  color: ${({ theme }) => theme.colors.primary.pureBlack};

  & p {
    &:not(:last-child) {
      padding-bottom: 1rem;
    }
  }

  & h1 {
    ${({ theme }) => theme.textStyles.titleH1}
  }
  & h2 {
    ${({ theme }) => theme.textStyles.titleH2}
  }
  & h3 {
    ${({ theme }) => theme.textStyles.titleH3}
  }
  & h4 {
    ${({ theme }) => theme.textStyles.titleH4}
  }
  & a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary.pureBlack};
    &:hover {
      text-decoration: underline;
    }
  }
  & strong,
  & b {
    font-weight: bold;
    font-variation-settings: 'wggt' 700;
  }
`
