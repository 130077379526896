import React, { FC, useEffect, useState } from 'react'
import { useWindowSize } from 'usehooks-ts'

import { sizes } from '../../theme'

import { DesktopNav } from './Desktop'
import { MobileNav } from './Mobile'
import { HeaderProps } from './types'

const Header: FC<HeaderProps> = (props) => {
  const size = useWindowSize()
  const [isMobile, setIsMobile] = useState<boolean>(false)

  useEffect(() => {
    setIsMobile(size?.width < sizes.mobile)
  }, [size?.width])

  return <>{isMobile ? <MobileNav {...props} /> : <DesktopNav {...props} />}</>
}

export default Header
