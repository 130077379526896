import React, { FC } from 'react'

import Footer, { FooterProps } from '../../components/Footer'
import { HeaderProps } from '../../components/Header/types'

import * as SC from './styled'

export type MainLayoutProps = {
  className?: string
  idContent?: string
  headerProps?: HeaderProps
  footerProps?: FooterProps
  children?: React.ReactNode
}

const MainLayout: FC<MainLayoutProps> = ({
  className,
  idContent = 'Content',
  headerProps,
  footerProps,
  children,
}) => {
  return (
    <>
      <SC.Layout className={className}>
        {headerProps && <SC.StyledHeader {...headerProps} />}
        <SC.Content id={idContent}>{children}</SC.Content>
        {footerProps && <Footer {...footerProps} />}
      </SC.Layout>
    </>
  )
}

export default MainLayout
