import styled, { css } from 'styled-components'
import Link from 'next/link'
import Image from 'next/image'

import { mq, sizes } from '../../theme'
import Icon from '../Icon'

export const Container = styled.footer`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary.pureWhite};
  margin-top: 10rem;
  z-index: ${({ theme }) => theme.zIndexes.background};
`

export const Content = styled.div`
  width: 100%;
  z-index: ${({ theme }) => theme.zIndexes.default};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media ${mq(sizes.tablet)} {
    flex-direction: row;
    align-items: flex-start;
    gap: 4rem;
    text-align: left;
  }
`

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  align-items: center;

  @media ${mq(sizes.tablet)} {
    margin-bottom: 0;
    align-items: flex-start;
  }
`

export const Sections = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;

  @media ${mq(sizes.tablet)} {
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 4rem;
    width: 100%;
    justify-content: space-around;
  }
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 50%;

  @media ${mq(sizes.tablet)} {
    margin-bottom: 0;
    width: initial;
  }
`

export const Brand = styled.span`
  ${({ theme }) => theme.textStyles.titleH4}
  font-size: 3.2rem;
  margin: 0 0 1rem 0;
  display: flex;
  align-items: center;
`

export const Logo = styled(Icon)`
  width: 6rem;
  height: 6rem;
`

const linkStyles = css<{ $noHoverEffect?: boolean }>`
  position: relative;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary.pureBlack};
  ${({ theme }) => theme.textStyles.text}
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  align-self: center;

  @media ${mq(sizes.tablet)} {
    align-self: flex-start;
  }

  &::after {
    content: '';
    position: absolute;
    width: ${({ $noHoverEffect }) => ($noHoverEffect ? '0' : '100%')};
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) => theme.colors.primary.pureBlack};
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  &:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`

export const iconStyles = css`
  width: 2.4rem;
  height: 2.4rem;
  color: ${({ theme }) => theme.colors.primary.red};
  margin-right: 1rem;
`

export const Mail = styled(Link)<{ $noHoverEffect?: boolean }>`
  ${linkStyles}
`

export const MailIcon = styled(Icon)`
  ${iconStyles}
`

export const Phone = styled(Link)<{ $noHoverEffect?: boolean }>`
  ${linkStyles}
`

export const PhoneIcon = styled(Icon)`
  ${iconStyles}
`

export const Title = styled.h3`
  margin: 0 0 1rem 0;
  ${({ theme }) => theme.textStyles.titleH4}
  font-weight: 500;
  font-size: 3.2rem;
`

export const Links = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0;
  padding: 0;
  list-style-type: none;
  align-items: center;

  @media ${mq(sizes.tablet)} {
    align-items: flex-start;
  }
`

export const LinkItemWrapper = styled.li`
  width: min-content;

  @media ${mq(sizes.tablet)} {
    width: max-content;
  }
`

export const LinkItem = styled(Link)<{ $noHoverEffect?: boolean }>`
  ${linkStyles}
`

export const Divider = styled.hr`
  width: 100%;
  margin: 4rem 0;
  border: none;
  border-top: 1px solid ${({ theme }) => theme.colors.primary.grey};
`

export const Mention = styled.span<{ $noHoverEffect?: boolean }>`
  ${linkStyles}
  margin-bottom: 6rem;
  align-self: center !important;
`

export const SpotsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndexes.background};
`

export const SpotsWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const Spot1 = styled(Image)`
  position: absolute;
  top: 30%;
  left: 5%;
  filter: blur(5rem);
`

export const StarsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndexes.background};
`

export const StarsWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const StarStyle = css`
  position: absolute;
  width: 4.8rem;
  height: 4.8rem;
`

export const Star1 = styled(Icon)`
  ${StarStyle};
  top: 10%;
  right: 0;
`

export const Star2 = styled(Icon)`
  ${StarStyle};
  top: 40%;
  left: 5%;
`
