import { FC } from 'react'
import cx from 'classnames'
import { LinkProps } from 'next/link'

import { Icons } from '../Icon'

import * as SC from './styled'

export type FooterProps = {
  className?: string
  brand: string
  mail: string
  phone: string
  categories: {
    title: string
    links: (LinkProps & {
      label: string
    })[]
  }[]
  mention: string
}

const Footer: FC<FooterProps> = ({
  className,
  mail,
  phone,
  categories,
  mention,
}) => {
  return (
    <SC.Container className={cx('Footer', className)}>
      <SC.Content>
        <SC.TopSection>
          <SC.Brand>
            <SC.Logo icon={Icons.logo} />
          </SC.Brand>
          <SC.Mail href={`mailto:${mail}`} $noHoverEffect>
            <SC.MailIcon icon={Icons.mail} />
            {mail}
          </SC.Mail>
          <SC.Phone href={`tel:${phone}`} $noHoverEffect>
            <SC.PhoneIcon icon={Icons.phone} />
            {phone}
          </SC.Phone>
        </SC.TopSection>
        <SC.Sections>
          {categories.map(({ title, links }, index) => (
            <SC.Section key={index}>
              <SC.Title>{title}</SC.Title>
              <SC.Links>
                {links.map((link, index) => (
                  <SC.LinkItemWrapper key={index}>
                    <SC.LinkItem {...link}>{link.label}</SC.LinkItem>
                  </SC.LinkItemWrapper>
                ))}
              </SC.Links>
            </SC.Section>
          ))}
        </SC.Sections>
      </SC.Content>
      <SC.Divider />
      <SC.Mention>{mention}</SC.Mention>
      <SC.SpotsContainer>
        <SC.SpotsWrapper>
          <SC.Spot1
            src="/images/home/arguments/spot1.png"
            alt="Red spot"
            width={317}
            height={230}
          />
        </SC.SpotsWrapper>
      </SC.SpotsContainer>
      <SC.StarsContainer>
        <SC.StarsWrapper>
          <SC.Star1 icon={Icons.star} />
          <SC.Star2 icon={Icons.star} />
        </SC.StarsWrapper>
      </SC.StarsContainer>
    </SC.Container>
  )
}

export default Footer
