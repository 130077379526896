import { FC, useState } from 'react'
import cx from 'classnames'

import { HeaderProps } from '../types'
import { Icons } from '../../Icon'

import { NavItem } from './components'
import * as SC from './styled'

export const MobileNav: FC<HeaderProps> = ({
  className,
  mainNav,
  logo,
  rightNav,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

  return (
    <SC.Header className={cx('Header', className)}>
      <SC.BurgerIcon icon={Icons.burger} onClick={() => setIsMenuOpen(true)} />
      <SC.LogoContainer href="/">
        <SC.Logo {...logo.icon} />
      </SC.LogoContainer>
      <SC.Menu $isOpen={isMenuOpen}>
        <SC.MenuHeader>
          <SC.CloseIcon
            icon={Icons.close}
            onClick={() => setIsMenuOpen(false)}
          />
        </SC.MenuHeader>
        <SC.MainNav>
          <SC.NavList>
            {mainNav.map((item, index) => (
              <NavItem key={index} {...item} />
            ))}
          </SC.NavList>
        </SC.MainNav>
        <SC.Divider />
        <SC.MainNav>
          <SC.NavList>
            {rightNav.map((item, index) => (
              <NavItem key={index} {...item} />
            ))}
          </SC.NavList>
        </SC.MainNav>
      </SC.Menu>
    </SC.Header>
  )
}
