import styled from 'styled-components'
import Link from 'next/link'

import Icon from '../Icon'

export enum ButtonVariants {
  primary = 'primary',
  secondary = 'secondary',
}

export const Container = styled.button<{ $variant: ButtonVariants }>`
  background-color: ${({ theme, $variant }) =>
    $variant === ButtonVariants.primary
      ? theme.colors.primary.pureBlack
      : theme.colors.primary.pureWhite};
  color: ${({ theme, $variant }) =>
    $variant === ButtonVariants.primary
      ? theme.colors.primary.pureWhite
      : theme.colors.primary.pureBlack};

  ${({ theme }) => theme.textStyles.text};
  border-radius: 0.4rem;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
`

export const StyledIcon = styled(Icon)`
  margin-left: 0.5rem;
  width: 2.4rem;
  height: 2.4rem;
`

export const A = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
`
