import * as icons from './icons'

export type IconType = keyof typeof icons

export const Icons: { [key in IconType]: key } = Object.keys(icons).reduce(
  (r, k) => ({ ...r, [k]: k }),
  {} as { [key in IconType]: key }
)

export type IconProps = {
  className?: string
  icon: IconType
  color?: string
  width?: number
  height?: number
  onClick?: (e?: React.MouseEvent<SVGSVGElement, MouseEvent>) => void
  preserveAspectRatio?:
    | 'none'
    | 'xMinYMin'
    | 'xMidYMin'
    | 'xMaxYMin'
    | 'xMinYMid'
    | 'xMidYMid'
    | 'xMaxYMid'
    | 'xMinYMax'
    | 'xMidYMax'
    | 'xMaxYMax'
}
