import styled, { css } from 'styled-components'
import Link from 'next/link'

import Icon from '../../Icon'

export const Header = styled.header`
  z-index: ${({ theme }) => theme.zIndexes.navigation};
  position: relative;
  width: 100%;
  padding: 1.6rem 0 0 0;

  display: flex;
  justify-content: space-between;
`

export const MainNav = styled.nav``

export const NavList = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  gap: 2.4rem;
`

const navItemStyles = css<{ $isActive?: boolean }>`
  position: relative;
  ${({ theme }) => theme.textStyles.link}
  margin-bottom: 1rem;
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.primary.red : theme.colors.primary.pureBlack};
  cursor: pointer;

  & svg {
    color: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.primary.red : theme.colors.primary.pureBlack};
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.primary.red : theme.colors.primary.pureBlack};
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  &:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`

export const NavItemLink = styled(Link)<{ $isActive?: boolean }>`
  ${navItemStyles}
`

export const NavItemEl = styled.span<{ $isActive?: boolean }>`
  list-style: none;
  ${navItemStyles}
  margin-bottom: 0;
`

export const SubNavIcon = styled(Icon)<{ $open?: boolean }>`
  width: 1.6rem;
  height: 1.6rem;
  margin-left: 0.5rem;
  transform: ${({ $open }) => ($open ? 'rotate(180deg)' : 'rotate(0)')};
`

export const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  margin-left: -12rem;
`

export const Logo = styled(Icon)`
  width: 5.5rem;
  height: 5.5rem;
`

export const LogoText = styled.span`
  ${({ theme }) => theme.textStyles.link}
  color: ${({ theme }) => theme.colors.primary.red};
  margin-top: 0.2rem;
  margin-left: -0.8rem;
`

export const RightNav = styled.nav``

export const NavItem = styled.li`
  list-style: none;
`

//
// SubNav
//

export const SubNavContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 7rem;
  left: 0;
  border: 0.1rem solid ${({ theme }) => theme.colors.primary.lightGrey};
  background-color: ${({ theme }) => theme.colors.primary.pureWhite};
  border-radius: 0.4rem;
  padding: 4.4rem;

  display: flex;
  justify-content: flex-start;

  box-shadow: -3px 34px 57px -1px rgba(0, 0, 0, 0.25);
  overflow: visible;
`

export const SubNavSections = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 4rem;
`

export const SubNavSection = styled.div``

export const SubNavSectionTitle = styled(Link)`
  ${({ theme }) => theme.textStyles.titleH4}
  color: ${({ theme }) => theme.colors.primary.red};
  font-size: 2.4rem;
  margin: 0 0 1.6rem 0;
`

export const SubNavSectionLinks = styled.ul`
  position: relative;
  padding: 0;
  list-style: none;
  margin-top: 0.8rem;
  margin-left: 1.6rem;

  &::before {
    content: '';
    display: block;
    width: 0.3rem;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.primary.red};
    position: absolute;
    left: -1.6rem;
    top: 0;
    opacity: 0.5;
  }
`

export const SubNavSectionLink = styled(Link)`
  position: relative;
  ${({ theme }) => theme.textStyles.text}
  color: ${({ theme }) => theme.colors.primary.pureBlack};
  display: block;
  width: fit-content;

  &:not(:last-child) {
    margin-bottom: 0.8rem;
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) => theme.colors.primary.pureBlack};
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  &:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`

// Not available

export const NotAvailableContainer = styled.div`
  position: absolute;
  top: 4rem;
  left: -1.5rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.primary.lightGrey};
  background-color: ${({ theme }) => theme.colors.primary.pureWhite};
  border-radius: 0.8rem;
  padding: 0.8rem;
  box-shadow: -3px 20px 40px -1px rgba(0, 0, 0, 0.2);
  overflow: visible;
  font-size: 1.4rem;
  text-align: center;
`
