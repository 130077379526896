import React, { FC } from 'react'
import cx from 'classnames'

import { IconProps } from '../Icon'

import * as SC from './styled'
import { ButtonVariants } from './styled'

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string
  variant?: ButtonVariants
  icon?: IconProps
  iconPosition?: 'left' | 'right'
  link?: string
  label?: string
}

const Button: FC<ButtonProps> = (props) => {
  const {
    className,
    children,
    variant = ButtonVariants.primary,
    icon,
    iconPosition = 'right',
    link,
    label,
    ...rest
  } = props

  const content = (
    <>
      {icon && iconPosition === 'left' && <SC.StyledIcon {...icon} />}
      {label || children}
      {icon && iconPosition === 'right' && <SC.StyledIcon {...icon} />}
    </>
  )

  if (link) {
    return (
      <SC.Container
        className={cx('Button', className)}
        $variant={variant}
        {...rest}
      >
        <SC.A href={link}>{content}</SC.A>
      </SC.Container>
    )
  }

  return (
    <SC.Container
      className={cx('Button', className)}
      $variant={variant}
      {...rest}
    >
      {content}
    </SC.Container>
  )
}

export default Button
